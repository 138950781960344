
.ul-elements {

  list-style-type: none;

}

.ul-main-elements {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ul-main-elements > li {
  margin: 0px 10px 0px 10px;
}

.ul-main-elements li * {
  color: aliceblue;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.288);
  border-radius: 10px;
  padding: 15px;
  transition: color 0.5s, background-color 0.5s, border-radius 0.5s, padding 0.5s;
}

.ul-main-elements li *:hover {
  color: black;
  background-color: aliceblue;
  border-radius: 5px;
  padding: 18px;
}


.ul-main-elements li {
  margin-top: 10px;
}

