
.home {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.home > .site-info0 {

}

.home > .site-info0 img {
  width: 595px;
  height: 646px;
}

.home > .site-info {
  display: grid;
  grid-template-rows: 400px auto auto;
  grid-gap: 20px;
  justify-items: center;
  grid-column: 2;
}

.home > .site-info h1 {
  margin-top: 0px;
  font-size: 100px;
  grid-row: 1;
  width: 600px;
  text-align: center;
  justify-self: center;
  align-self: center;
}

.home > .site-info h2 {
  font-size: 35px;
  grid-row: 2;
}

.home > .site-info p {
  font-size: 20px;
  width: 500px;
  grid-row: 3;
}
