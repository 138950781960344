.App {
  text-align: center;
  display: grid;
  grid-template-rows: 50px auto auto;
  grid-gap: 20px;
}

.router-provider {
  grid-row: 2;
}

html, body {
  background-color: rgb(20, 20, 20);
}

h1, h2, h3, h4, h5, h6, p, li, button, #text {
  color: aliceblue;
}

a {
  color: aqua;
}

@media only screen and (max-width: 600px) {
  
  .CHeader {
    width: fit-content;
  }

  .router-provider {
    width: fit-content;
  }

  .footer-pg {
    width: fit-content;
  }

  .site-info0 > img {
    width: 400px !important;
    height: auto !important;
  }

  .site-info {
    width: 400px !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .site-info * {
    text-align: center !important;
    justify-content: center;
    align-items: center;
  }

  .site-info h1 {
    font-size: 50px !important;
  }

  .site-info h2 {
    font-size: 30px !important;
  }

  .site-info p {
    font-size: 20px !important;
    width: 400px !important;
  }

  .home {
    display: flex !important;
    flex-direction: column;
    align-items: baseline;
  }

  .contact {
    width: 430px !important;
  }

  #ul-socials {
    width: 300px !important;
  }

  #ul-socials li {
    justify-self: center !important;
    align-self: center !important;
  }

  #ul-socials a {
    font-size: 20px;
  }

  .aboutus {
    display: flex !important;
    flex-direction: column !important;
    width: 400px !important;
  }


  .image-gallery-image {
    max-width: 400px !important;
  }


  /*
  .ul-elements {
    list-style-type: none;
  }

  .ul-main-elements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline !important;
  }

  .ul-main-elements > li {
    margin: 20px 0px 10px 0px;
  }

  .ul-main-elements li * {
    color: aliceblue;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.288);
    border-radius: 10px;
    padding: 15px;
  }

  .ul-main-elements li {
    margin-top: 30px;
  } */



}