@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: 'coolvetica';
  src: url('../fonts/coolvetica rg.otf') format('opentype');
}

/*
.aboutus > .services-ul {
  list-style: none;
}

.aboutus > .services-ul > li {
  margin: 15px 0 15px 0;
}

.aboutus > .services-ul  img {
  width: 500px;
  height: auto;
  box-shadow: 1px 10px 10px black;
}

.aboutus > .services-ul h3 {
  letter-spacing: 5px;
  font-family: 'coolvetica', 'Courier', 'monospace';
  font-size: 100px;
  text-transform: uppercase;
  margin: 0;
} 

.aboutus > .services-ul p {
  font-size: 30px;
}
*/

.aboutus {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.aboutus > .image-slider-div {
  justify-self: baseline;
  align-self: baseline;
}

.image-slider-div .image-gallery-image {
  max-width: 550px;
}

.aboutus .rating-div {
  background-color: rgba(100, 100, 100, 0.2);
  padding: 30px;
  box-shadow: 3px 3px 3px black;
  display: grid;
}

.aboutus > .rating-div > .rating-part {

}

.aboutus .rating-div > p {
  position: relative;
  bottom: 35px;
  left: 250px;
}

.aboutus .rating-div > textarea {
  margin-top: 10px;
  margin-left: 50px;
  width: 500px;
  height: 100px;
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
  border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
  border-image: none;
  border-radius: 6px 6px 6px 6px;
  border-style: none solid solid none;
  border-width: medium 1px 1px medium;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
  color: #cecece;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 1em;
  line-height: 1.4em;
  padding: 5px 8px;
  transition: background-color 0.2s ease 0s;
}


textarea:focus {
    background: none repeat scroll 0 0 #FFFFFF;
    outline-width: 0;
}


.sendbutton {
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  isolation: isolate;
}

.sendbutton {
  color: #eceaea;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  outline: 2px solid transparent;
  outline-offset: 4px;
  position: relative;
  width: 140px;
  justify-self: center;
  background-color: rgb(22, 22, 22);

  transition: width 0.5s, height 0.5s, background-color 0.5s;
}

.sendbutton:hover {
  background-color: rgb(95, 95, 95);
}

.aboutus .ratings > .ratings-div {
  display: grid;
}

.aboutus .ratings > .ratings-div .ratings-div-pg {
  box-shadow: 3px 3px 3px black;
  width: 300px;
  justify-self: center;
  align-self: center;
  border: 10px solid transparent;
  border-radius: 10px;
  background-color: rgba(75, 82, 85, 0.2);;
  margin: 10px 0px 10px 0px;

}

.aboutus .ratings > .ratings-div .ratings-div-p1 {
  
}

.aboutus .ratings > .ratings-div #p1 {

}

.aboutus .ratings > .ratings-div #p2 {
  background-color: rgba(0, 0, 0, 0.5);
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: baseline;
  border-radius: 10px;

}

.the-ul-services {
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 20px;
}
