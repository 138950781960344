@font-face {
  font-family: 'coolvetica';
  src: url('../fonts/coolvetica rg.otf') format('opentype');
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  background-color: rgb(40, 40, 40);
  box-shadow: 15px 115px 100px rgba(0, 0, 0, 0.2);
}

.contact #message {
  font-size: 20px;
  font-style: italic;

}

.contact ul {
  list-style-type: none;
  text-align: center;
}

.contact > ul .page-icon {
  width: 15px;
  height: 15px;
  margin-left: 10px;
}

.contact #ul-number {
  margin: 10px 0px 20px 0px;
}

.contact #ul-socials {
  margin: 10px 0px 35px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 25px;
  padding-top: 50px;
  background-color: rgba(0, 0, 0, 0.1);
}

.contact #ul-socials > ul a {
  text-decoration: none;
  color: rgb(33, 143, 206);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.contact #ul-socials > ul {
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}

.contact > ul li {
  margin: -15px 0px 20px 0px;
  font-family: 'coolvetica', 'Courier', 'monospace';
  letter-spacing: 2px;
  font-size: 30px;
}


.image-gallery-svg{

}

.image-slider-div {

}

.image-gallery-image {
  height: 500px !important;
  width: auto !important;
}

.rating-div {
  margin: 40px 0px 40px 0px;
}

.icon {
  width: 40px;
  height: auto;
}


@media only screen and (max-width: 600px) {
  .contact a {
    font-size: 15px;
  }
  


}