#copy-right {
    height: auto;
}

.address-links{
	    line-height: 32px;
		color: #777777;
}
.copy-right-sec{
	padding: 1.8rem;
    background: #394d5349;
    
    color: #fff;
    text-align: center;
}
.copy-right-sec a{
	color: #fcd462;
    font-weight: 500;
}
.copy-right-sec a {
  text-decoration:none;
}

/* footer section end */